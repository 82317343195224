interface IOptions {
  precision?: number;
  verbose?: boolean;
}

export const ntc = ( cents: number, options: IOptions = {}) => {
  const { precision = 2, verbose = true } = options;

  if ( cents != null ) {
    const dollarAmount: number = cents / 100;

    let formattedValue: string;
    if ( dollarAmount >= 0 ) {
      formattedValue = `$${dollarAmount.toFixed( precision )}`;
    } else {
      formattedValue = `-$${( -dollarAmount ).toFixed( precision )}`;
    }
    // Remove "zero" cents where possible
    if ( !verbose ) {
      formattedValue = formattedValue.replace( '.00', '' );
    }

    return formattedValue;
  }

  return '';
};

export const ntcOrFree = ( cents: number, options?: IOptions ) => {
  if ( cents != null ) {
    if ( cents === 0 ) {
      return 'Free';
    }

    return ntc( cents, options );
  }

  return '';
};
